// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-quiz-js": () => import("./../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-templates-blog-page-js": () => import("./../src/templates/BlogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-books-page-js": () => import("./../src/templates/BooksPage.js" /* webpackChunkName: "component---src-templates-books-page-js" */),
  "component---src-templates-compare-js": () => import("./../src/templates/Compare.js" /* webpackChunkName: "component---src-templates-compare-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-feature-js": () => import("./../src/templates/Feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-prices-page-js": () => import("./../src/templates/PricesPage.js" /* webpackChunkName: "component---src-templates-prices-page-js" */),
  "component---src-templates-pricing-enterprice-page-js": () => import("./../src/templates/PricingEnterpricePage.js" /* webpackChunkName: "component---src-templates-pricing-enterprice-page-js" */),
  "component---src-templates-pricing-reseller-page-js": () => import("./../src/templates/PricingResellerPage.js" /* webpackChunkName: "component---src-templates-pricing-reseller-page-js" */),
  "component---src-templates-solution-js": () => import("./../src/templates/Solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-technology-js": () => import("./../src/templates/Technology.js" /* webpackChunkName: "component---src-templates-technology-js" */),
  "component---src-templates-videoblog-page-js": () => import("./../src/templates/VideoblogPage.js" /* webpackChunkName: "component---src-templates-videoblog-page-js" */)
}

